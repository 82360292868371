import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import { Helmet } from 'react-helmet'

export const PressePageTemplate = ({ title, content, contentComponent, image }) => {
  const PageContent = contentComponent || Content

  return (
    <div>
      <section className="section hero has-overlay section--gradient background--image background--fixed has-text-white level" style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`
      }}>
        <div className="container level">
          <div className="section">
            <div className="columns">
              <div className="column ">
                <h1 className="title has-text-white">{title}<b></b></h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <div className="section">

                <h2 className="title">Unsere Pressemitteilungen</h2>

                <div className="columns">
                  <div className="column">
                    <p>Pressemitteilung vom <b>12.05.2020</b> | Radentscheid Bayreuth gibt Zwischenstand bekannt</p>
                  </div>
                  <div className="column">
                    <a className="btn" target="__blank" href="https://drive.google.com/file/d/1oS4Pr3_Ul0Wmj_Fj1M9ErFR1h5EXXXlp/view?usp=sharing">Download</a>
                  </div>
                </div>

                <div className="columns">
                  <div className="column">
                    <p>Pressemitteilung vom <b>05.03.2020</b> | KIDICAL MASS in Bayreuth</p>
                  </div>
                  <div className="column">
                    <a className="btn" target="__blank" href="https://drive.google.com/open?id=1Nx1pRTIhWfyzoFaaCFiXZe91vAgTyEZ8">Download</a>
                  </div>
                </div>

                <div className="columns">
                  <div className="column">
                    <p>Pressemitteilung vom <b>29.02.2020</b> | OB-Kandidat*innen über Radverkehr</p>
                  </div>
                  <div className="column">
                    <a className="btn" target="__blank" href="https://drive.google.com/open?id=1lC49Cey4BsUE9nr_MoF7mdkrWKpOHALN">Download</a>
                  </div>
                </div>

                <div className="columns">
                  <div className="column">
                    <p>Pressemitteilung vom <b>28.01.2020</b> | Bekanntgabe der Forderungen</p>
                  </div>
                  <div className="column">
                    <a className="btn" target="__blank" href="https://drive.google.com/open?id=1NEmR09sUkqYs2ZljI8WNoIToar-7o6Sg">Download</a>
                  </div>
                </div>

                <h2 className="title">Kontaktmöglichkeiten</h2>
                <p>Für weitere Fragen stehen wir gerne zur Verfügung.</p>
                <p>Daniel Brunnabend, <a href="mailto:d.brunnabend@radentscheid-bayreuth.de">d.brunnabend@radentscheid-bayreuth.de</a></p>
                <p>Jakob Ortmann, <a href="mailto:j.ortmann@radentscheid-bayreuth.de">j.ortmann@radentscheid-bayreuth.de</a></p>

                <PageContent className="content" content={content} />

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

PressePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const PressePage = ({ data }) => {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <PressePageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        image={post.frontmatter.image}
      />
      <Helmet titleTemplate="%s | Radentscheid Bayreuth">
        <title>{`${post.frontmatter.title}`}</title>
        <meta
          name="description"
          content={`${post.frontmatter.description}`}
        />
      </Helmet>
    </Layout>
  )
}

PressePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PressePage

export const pressePageQuery = graphql`
  query PressePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
